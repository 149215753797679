import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import BreadCrumb from '../../../components/BreadCrumb';
import {withLocaleProvider} from '../../../locale/LocaleProvider';
import MyIframe from '../../../components/MyIframe';

export const ChineseCoursePage = () => {
  const [demo, setDemo] = useState<{url: string; title: string} | undefined>();
  const {t, i18n} = useTranslation();

  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Chinese-course:seo:title')}
        description={t('Chinese-course:seo:description')}
        url={t('Chinese-course:seo:url')}
      />
      <Layout beforeFooter>
        <BreadCrumb
          routes={[
            {
              name: t('Nav:products:title'),
            },
            {
              name: t('Nav:products:link-1:title'),
              path: t('Nav:products:link-1:url'),
            },
            {
              name: t('Language-courses:contents:card-zh:title'),
              path: t('Language-courses:contents:card-zh:link-url'),
            },
          ]}
        />
        <div className="breadcrumbs-v3 img-zh">
          <div className="container text-center">
            <p />
            <h1 className="no-margin">{t('Chinese-course:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <p
            dangerouslySetInnerHTML={{__html: t('Chinese-course:contents:text')}}
          />
          <div className="margin-bottom-40"></div>
          <div className="headline">
            <h2>{t('Chinese-course:contents:levels:title')}</h2>
          </div>

          <div className="row margin-bottom-20">
            <div className="col-md-12 single-course">
              <h3>{t('Chinese-course:contents:levels:title')}</h3>
              <span>
                <strong>
                  {t('Chinese-course:contents:levels:level-1:info-level:title')}
                </strong>
                {t('Chinese-course:contents:levels:level-1:info-level:text')}
              </span>
              <span>
                <strong>
                  {t('Chinese-course:contents:levels:level-1:info-demo:title')}
                </strong>
                <button
                  className="button-link"
                  onClick={() =>
                    setDemo({
                      url: t(
                        'Chinese-course:contents:levels:level-1:info-demo:link-url'
                      ),
                      title: t(
                        'Chinese-course:contents:levels:level-1:info-demo:title'
                      ),
                    })
                  }
                >
                  {t(
                    'Chinese-course:contents:levels:level-1:info-demo:link-text'
                  )}
                </button>
              </span>
              <p>{t('Chinese-course:contents:levels:level-1:text')}</p>
            </div>
          </div>
        </div>
        {demo && (
          <MyIframe
            title={demo.title}
            url={demo.url}
            onClose={() => setDemo(undefined)}
          />
        )}
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', ChineseCoursePage);
